import { FC } from 'react'
import styled, { css } from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import { UseGetAdsByPlacementCode_AdFragment } from 'generated/graphql'
import Portal from '@app.components/Portal'
import { useFullScreenAdBanner } from '@app.feature/ad/hooks/useFullScreenAdBanner'
import { useFullScreenAdBannerCookies } from '@app.feature/ad/hooks/useFullScreenAdBannerCookies'
import { useBodyScrollHidden } from 'hooks/useBodyScrollHidden'
import FullScreenAdBannerItem from './components/FullScreenAdBannerItem'

interface IFProps {
  ads: Array<UseGetAdsByPlacementCode_AdFragment>
}

const FullScreenAdBanner: FC<IFProps> = ({ ads }) => {
  const {
    isOpenFullScreenAdBanner,
    handleFullScreenAdBannerClose,
    addTemporaryCloseSessionStorage,
  } = useFullScreenAdBanner()

  const { addDismissForTodayCookie } = useFullScreenAdBannerCookies()

  const handleTemporaryClose = () => {
    addTemporaryCloseSessionStorage()
    handleFullScreenAdBannerClose()
  }

  const handleDismissForToday = () => {
    addDismissForTodayCookie()
    handleFullScreenAdBannerClose()
  }

  const isPaginationEnabled = ads.length > 1

  useBodyScrollHidden(isOpenFullScreenAdBanner)

  if (!isOpenFullScreenAdBanner) return null

  return (
    <Portal id="fullscreen-ad-banner-portal">
      <StyledWrapper>
        <div>
          <section className="fullscreen-ad-banner-desktop-wrapper">
            <Swiper
              key="fullscreen-ad-banner-desktop-swiper"
              slidesPerView={1}
              loop
              autoplay={{ delay: 3000, disableOnInteraction: false }}
              pagination={{
                type: 'fraction',
                el: '.custom-pagination',
                enabled: isPaginationEnabled,
              }}
            >
              {ads.map((ad) => (
                <SwiperSlide
                  key={`fullscreen-banner-desktop-placement-${ad.id}`}
                >
                  <FullScreenAdBannerItem ad={ad} />
                </SwiperSlide>
              ))}
              {isPaginationEnabled && <mark className="custom-pagination" />}
            </Swiper>
          </section>
          <section className="button-wrapper">
            <button type="button" onClick={handleDismissForToday}>
              오늘은 그만 볼게요
            </button>
            <button type="button" onClick={handleTemporaryClose}>
              닫기
            </button>
          </section>
        </div>
      </StyledWrapper>
    </Portal>
  )
}

export default FullScreenAdBanner

const StyledWrapper = styled.div`
  ${({ theme }) => css`
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;

    & > div {
      width: 500px;
      height: 385px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;

      .swiper {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background-color: ${theme.background.bg_8};
      }

      .button-wrapper {
        display: flex;
        border-top: 1px solid #ddd;

        button {
          background-color: ${theme.background.bg_8};
          width: 50%;
          height: 44px;
          border: none;
          color: #333;
          font-weight: 500;
          font-size: 14px;
          cursor: pointer;

          &:first-child {
            border-bottom-left-radius: 10px;
          }

          &:last-child {
            border-bottom-right-radius: 10px;
          }

          &:not(:last-child) {
            border-right: 1px solid #ddd;
          }
        }
      }

      .custom-pagination {
        position: absolute;
        left: auto;
        right: 15px;
        bottom: 15px;
        z-index: 400;
        cursor: default;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 34px;
        height: 22px;
        background-color: rgba(0, 0, 0, 0.7);
        border-radius: 11px;
        padding: 2px 4px;
        color: ${theme.background.bg_8};
        font-size: 12px;
        font-weight: bold;
        line-height: 2;
      }
    }
  `}
`
