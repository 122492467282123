import { FC } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import styled from 'styled-components'
import { UseGetAdsByPlacementCode_AdFragment } from 'generated/graphql'
import { GTM_EVENT } from '@app.feature/ad/constants/gtm'
import useAdImpressionTracking from '@app.feature/ad/hooks/useAdImpressionTracking'
import { pushGTMCampaignId } from '@app.feature/ad/modules/gtmEvents'
import { optimizeImageUrl, imageLoader } from 'utils/optimizeImageUrl'

interface IFProps {
  ad: UseGetAdsByPlacementCode_AdFragment
}

const FULL_SCREEN_BANNER_DESKTOP_IMAGE_SIZE = {
  width: 500,
  height: 340,
}

const FullScreenAdBannerItem: FC<IFProps> = ({ ad }) => {
  const alt = `${ad.campaign?.campaignGroup.name} 캠페인 pc 전면팝업 배너 이미지`

  const handleClickAdBannerItem = () => {
    pushGTMCampaignId({
      eventName: GTM_EVENT.FULLSCREEN_POPUP.PC?.CLICK_EVENT || '',
      campaignId: ad.campaign?.id || '',
    })

    if (ad.campaign?.landingURL) {
      window.open(ad.campaign.landingURL, '_blank', 'noopener, noreferrer')
    }
  }

  const trackImpression = () => {
    if (ad.campaign?.id) {
      pushGTMCampaignId({
        eventName: GTM_EVENT.FULLSCREEN_POPUP.PC.IMPRESSION_EVENT || '',
        campaignId: ad.campaign.id,
      })
    }
  }

  const { containerRef } = useAdImpressionTracking<HTMLDivElement>({
    onVisible: trackImpression,
  })

  const activityUrl = ad.activity?.id ? `/activity/${ad.activity?.id}` : ''

  return (
    <StyledWrapper onClick={handleClickAdBannerItem} ref={containerRef}>
      <Link href={activityUrl}>
        <a rel="nofollow sponsored">
          <Image
            src={optimizeImageUrl({
              url: ad.image?.url,
              width: FULL_SCREEN_BANNER_DESKTOP_IMAGE_SIZE.width,
              height: 'auto',
            })}
            width={FULL_SCREEN_BANNER_DESKTOP_IMAGE_SIZE.width}
            alt={alt}
            loader={imageLoader}
            layout="fill"
            objectFit="contain"
          />
        </a>
      </Link>
    </StyledWrapper>
  )
}

export default FullScreenAdBannerItem

const StyledWrapper = styled.div`
  height: 340px;
`
