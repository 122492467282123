export enum PC_AD_CODE {
  MAIN = 'pc-main-list-banner',
  HOTLINE = 'pc-hotline-banner',
  RECOMMEND_ACTIVITY_S = 'pc-recommend-activity-s-banner',
  RECOMMEND_ACTIVITY_A = 'pc-recommend-activity-a-banner',
  WEEKLY_POPULAR = 'pc-weekly-popular-activity',
  RECRUIT = 'pc-recruit-banner',
  RIGHT_SIDEBAR = 'pc-right-sidebar-all-of-page',
  HORIZONTAL = 'web-activity-horizontal-ad',
  RECRUIT_QNA_BOTTOM = 'pc-recruit-qna-bottom',
  COVER_LETTER_SEARCH = 'pc-coverletter-search-banner',
  LIVE_ACTIVITY = 'live-activity',
  FULLSCREEN_POPUP = 'pc-full-popup',
  EDUCATION_TOP_PINNED_BANNER = 'pc-education-top-pinned-banner',
}

export enum MOBILE_AD_CODE {
  MAIN = 'mobile-main-list-banner',
  HOTLINE = 'mobile-hotline-banner',
  RECOMMEND_ACTIVITY_S = 'mobile-recommend-activity-s-banner',
  RECOMMEND_ACTIVITY_A = 'mobile-recommend-activity-a-banner',
  WEEKLY_POPULAR = 'mobile-weekly-popular-activity',
  RECRUIT = 'mobile-recruit-banner',
  RECRUIT_QNA_BOTTOM = 'mobile-recruit-qna-bottom',
  COVER_LETTER_SEARCH = 'mobile-coverletter-search-banner',
  LIVE_ACTIVITY = 'live-activity',
  FULLSCREEN_POPUP = 'mobile-full-popup',
  EDUCATION_TOP_PINNED_BANNER = 'mobile-education-top-pinned-banner',
}

export type AD_CODE = PC_AD_CODE | MOBILE_AD_CODE

export type TAdCodeKey =
  | 'MAIN'
  | 'HOTLINE'
  | 'RECOMMEND_ACTIVITY_S'
  | 'RECOMMEND_ACTIVITY_A'
  | 'WEEKLY_POPULAR'
  | 'RECRUIT'
  | 'RIGHT_SIDEBAR'
  | 'HORIZONTAL'
  | 'RECRUIT_QNA_BOTTOM'
  | 'COVER_LETTER_SEARCH'
  | 'LIVE_ACTIVITY'
  | 'FULLSCREEN_POPUP'
  | 'EDUCATION_TOP_PINNED_BANNER'

export type TAdCodeValue = {
  PC: PC_AD_CODE
  MOBILE?: MOBILE_AD_CODE
}

export type TAdCode = Record<TAdCodeKey, TAdCodeValue>

export const AD_CODE_ID: TAdCode = {
  MAIN: {
    PC: PC_AD_CODE.MAIN,
    MOBILE: MOBILE_AD_CODE.MAIN,
  },
  HOTLINE: {
    PC: PC_AD_CODE.HOTLINE,
    MOBILE: MOBILE_AD_CODE.HOTLINE,
  },
  RECOMMEND_ACTIVITY_S: {
    PC: PC_AD_CODE.RECOMMEND_ACTIVITY_S,
    MOBILE: MOBILE_AD_CODE.RECOMMEND_ACTIVITY_S,
  },
  RECOMMEND_ACTIVITY_A: {
    PC: PC_AD_CODE.RECOMMEND_ACTIVITY_A,
    MOBILE: MOBILE_AD_CODE.RECOMMEND_ACTIVITY_A,
  },
  LIVE_ACTIVITY: {
    PC: PC_AD_CODE.LIVE_ACTIVITY,
    MOBILE: MOBILE_AD_CODE.LIVE_ACTIVITY,
  },
  WEEKLY_POPULAR: {
    PC: PC_AD_CODE.WEEKLY_POPULAR,
    MOBILE: MOBILE_AD_CODE.WEEKLY_POPULAR,
  },
  RECRUIT: {
    PC: PC_AD_CODE.RECRUIT,
    MOBILE: MOBILE_AD_CODE.RECRUIT,
  },
  RIGHT_SIDEBAR: {
    PC: PC_AD_CODE.RIGHT_SIDEBAR,
  },
  HORIZONTAL: {
    PC: PC_AD_CODE.HORIZONTAL,
  },
  RECRUIT_QNA_BOTTOM: {
    PC: PC_AD_CODE.RECRUIT_QNA_BOTTOM,
    MOBILE: MOBILE_AD_CODE.RECRUIT_QNA_BOTTOM,
  },
  COVER_LETTER_SEARCH: {
    PC: PC_AD_CODE.COVER_LETTER_SEARCH,
    MOBILE: MOBILE_AD_CODE.COVER_LETTER_SEARCH,
  },
  FULLSCREEN_POPUP: {
    PC: PC_AD_CODE.FULLSCREEN_POPUP,
    MOBILE: MOBILE_AD_CODE.FULLSCREEN_POPUP,
  },
  EDUCATION_TOP_PINNED_BANNER: {
    PC: PC_AD_CODE.EDUCATION_TOP_PINNED_BANNER,
    MOBILE: MOBILE_AD_CODE.EDUCATION_TOP_PINNED_BANNER,
  },
}

export enum ACTIVITY_NOTICE_AD_CODE {
  INTERN_NOTICE = 'activity-intern-notice-banner',
  RECRUIT_NOTICE = 'activity-recruit-notice-banner',
  ACTIVITY_NOTICE = 'activity-activity-notice-banner',
  CONTEST_NOTICE = 'activity-contest-notice-banner',
  EDUCATION_NOTICE = 'activity-education-notice-banner',
}

export type ACTIVITY_NOTICE_AD_CODE_KEY =
  | 'INTERN_NOTICE'
  | 'RECRUIT_NOTICE'
  | 'ACTIVITY_NOTICE'
  | 'CONTEST_NOTICE'
  | 'EDUCATION_NOTICE'

export const ACTIVITY_NOTICE_AD_CODE_ID: Record<
  ACTIVITY_NOTICE_AD_CODE_KEY,
  ACTIVITY_NOTICE_AD_CODE
> = {
  INTERN_NOTICE: ACTIVITY_NOTICE_AD_CODE.INTERN_NOTICE,
  RECRUIT_NOTICE: ACTIVITY_NOTICE_AD_CODE.RECRUIT_NOTICE,
  ACTIVITY_NOTICE: ACTIVITY_NOTICE_AD_CODE.ACTIVITY_NOTICE,
  CONTEST_NOTICE: ACTIVITY_NOTICE_AD_CODE.CONTEST_NOTICE,
  EDUCATION_NOTICE: ACTIVITY_NOTICE_AD_CODE.EDUCATION_NOTICE,
}
