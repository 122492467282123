import { FC } from 'react'
import { Badge } from '@material-ui/core'
import Avatar, { AvatarProps } from '@material-ui/core/Avatar'
import { makeStyles } from '@material-ui/core/styles'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import clsx from 'clsx'
import gql from 'graphql-tag'
import { CameraIcon } from 'svgIcons'
import { UserAvatar_UserFragment } from 'generated/graphql'
import PersonIcon from 'svgIcons/PersonLongBody'

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#D8D8D8',
  },
  personIcon: {
    width: '56.0416%',
    color: 'white',
    height: 'auto',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  displayNone: {
    display: 'none',
  },
  cameraIcon: {
    width: '24px',
    height: '24px',
  },
  anchorOriginBottomRightCircle: {
    right: '20%',
    bottom: '20%',
  },
}))

interface UserAvatarProps {
  className?: string
  avatarProps?: AvatarProps
  currentUser?: UserAvatar_UserFragment | null
  classes?: Partial<ClassNameMap<'root' | 'personIcon'>>
}

const UserAvatar: FC<UserAvatarProps> = ({
  className = '',
  avatarProps,
  currentUser,
  classes: classesProp,
}) => {
  const classes = useStyles({ classes: classesProp })
  const avatarURL = currentUser?.profile?.identificationImage?.url

  return (
    <Badge
      overlap="circle"
      classes={{
        anchorOriginBottomRightCircle: classes.anchorOriginBottomRightCircle,
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      className={clsx(classes.cursorPointer, className)}
      badgeContent={<CameraIcon className={classes.cameraIcon} />}
    >
      <Avatar src={`${avatarURL}?d=110xauto`} {...avatarProps}>
        {!avatarURL && <PersonIcon className={classes.personIcon} />}
      </Avatar>
    </Badge>
  )
}

export default UserAvatar

// TODO
// use avatar url
gql`
  fragment UserAvatar_user on User {
    id
    username
    profile {
      id
      identificationImage {
        id
        url
      }
    }
  }
`
