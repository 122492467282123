import { FC, useState } from 'react'
import { useCookies } from 'react-cookie'
import LazyLoad from 'react-lazyload'
import gql from 'graphql-tag'
import styled from 'styled-components'
import {
  NotificationScope,
  useGqlScreenHomeDesktopQuery,
} from 'generated/graphql'
import { useGqlCommunityCurrentUserQuery } from 'generated/graphql.community'
import BestStory from '@app.components/BestStory'
import RightSideBarBannerStatusDesktop from '@app.feature/ad/desktop/AllCoverBanner/RightSideBarBanner/RightSideBarBannerStatus.desktop'
import FullScreenAdBannerContainer from '@app.feature/ad/desktop/FullScreenAdBanner/FullScreenAdBannerContainer'
import HotLineBannerStatusDesktop from '@app.feature/ad/desktop/HotLineBannerStatus.desktop'
import LiveActivityStatusDesktop from '@app.feature/ad/desktop/LiveActivity/LiveActivityStatus.desktop'
import LiveActivityListLoadingDesktop from '@app.feature/ad/desktop/LiveActivity/loading/LiveActivityListLoading.desktop'
import MainBannerStatusDesktop from '@app.feature/ad/desktop/MainBanner/MainBannerStatus.desktop'
import PopularActivityStatusDesktop from '@app.feature/ad/desktop/PopularActivity/PopularActivityStatus.desktop'
import PopularActivityLoadingDesktop from '@app.feature/ad/desktop/PopularActivity/loading/PopularActivityLoading.desktop'
import PopularRecruitStatusDesktop from '@app.feature/ad/desktop/PopularRecruit/PopularRecruitStatus.desktop'
import PopularRecruitLoadingDesktop from '@app.feature/ad/desktop/PopularRecruit/loading/PopularRecruitLoading.desktop'
import RecommendBannerListDesktop from '@app.feature/ad/desktop/RecommendBanner/RecommendBannerList.desktop'
import { SHOW_ALTERNATIVE_MODAL } from '@app.feature/ad/hooks/useFullScreenAdBanner'
import PopularChatRoomsDesktop from '@app.feature/home/components/PopularChat/desktop/PopularChatRooms.desktop'
import useSignupCookies, {
  SIGNUP_MODAL_COOKIE,
} from '@app.feature/home/hook/useSignupCookies'
import UserInfoModal from '@app.feature/userInfoModal/desktop/UserInfoModal.desktop'
import { BestStoryType } from 'app.constants/enums'
import InstallAppBanner from '../components/InstallAppBanner/InstallAppBanner'
import LinkareerMagazine from '../components/LinkareerMagazine'
import ManagerReply from '../components/ManagerReply'
import MyActivities from '../components/MyActivities'
import RightBanner from '../components/RightBanner'
import ServiceFeedbackBanner from '../components/ServiceFeedbackBanner/ServiceFeedbackBanner'

gql`
  query gqlScreenHomeDesktop($notificationType: NotificationScope) {
    currentUser {
      isInitializedUserInfo
      ...MyActivities_currentUser
      ...ActivityListCardItem_currentUser
    }
  }
`

const ScreenHomeDesktop: FC = () => {
  const [cookies] = useCookies()
  const { addSignupModalCookie } = useSignupCookies()
  const [isOpenUserInfoModal, setIsOpenUserInfoModal] = useState<boolean>(false)

  const { data, loading } = useGqlScreenHomeDesktopQuery({
    variables: { notificationType: NotificationScope.NOTIFICATION },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      const canOpenModal =
        !cookies?.[SIGNUP_MODAL_COOKIE] &&
        data?.currentUser?.type === 'NORMAL' &&
        data.currentUser?.isInitializedUserInfo === false &&
        sessionStorage.getItem(SHOW_ALTERNATIVE_MODAL) === 'true'
      setIsOpenUserInfoModal(canOpenModal)
    },
  })

  const { data: communityUserData, loading: communityUserLoading } =
    useGqlCommunityCurrentUserQuery({
      variables: {
        isMypage: false,
      },
    })

  const handleUserInfoModalClose = () => {
    addSignupModalCookie()
    setIsOpenUserInfoModal(() => false)
  }

  return (
    <StyledWrapper>
      <section className="main-section">
        <article className="lt-article">
          <HotLineBannerStatusDesktop />
          <div className="row-box">
            <div className="column-box">
              <LinkareerMagazine />
              <ManagerReply />
            </div>
            <MainBannerStatusDesktop />
          </div>
        </article>
        <article className="rt-article">
          <MyActivities
            currentUser={data?.currentUser}
            communityUser={communityUserData}
            isLoading={loading || communityUserLoading}
          />
          <BestStory
            className="best-story"
            type={BestStoryType.ALL}
            isRadius
            isExpandBtn={false}
          />
        </article>
      </section>
      <RecommendBannerListDesktop currentUser={data?.currentUser} />
      <LazyLoad height={'100%'} placeholder={<PopularActivityLoadingDesktop />}>
        <PopularActivityStatusDesktop
          currentUser={data?.currentUser || undefined}
          isScreenQueryLoading={loading}
        />
      </LazyLoad>
      <LazyLoad height={'100%'} placeholder={<PopularRecruitLoadingDesktop />}>
        <PopularRecruitStatusDesktop
          currentUser={data?.currentUser ?? undefined}
          isScreenQueryLoading={loading}
        />
      </LazyLoad>
      <LazyLoad height={'100%'}>
        <PopularChatRoomsDesktop />
      </LazyLoad>
      <LazyLoad
        height={'100%'}
        placeholder={<LiveActivityListLoadingDesktop />}
      >
        <LiveActivityStatusDesktop
          currentUser={data?.currentUser}
          isScreenQueryLoading={loading}
        />
      </LazyLoad>
      <aside>
        <InstallAppBanner />
        <RightBanner />
        <ServiceFeedbackBanner />
        <RightSideBarBannerStatusDesktop />
      </aside>

      {isOpenUserInfoModal && (
        <UserInfoModal
          isModalOpen={isOpenUserInfoModal}
          handleModalClose={handleUserInfoModalClose}
        />
      )}
      <FullScreenAdBannerContainer />
    </StyledWrapper>
  )
}

export default ScreenHomeDesktop

const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 70px;
  .main-section {
    display: flex;
    gap: 30px;
    max-width: 1100px;
    .lt-article {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    .rt-article {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      .best-story {
        max-width: 350px;
      }
    }
  }

  .row-box {
    display: flex;
    gap: 20px;
  }
  .column-box {
    display: flex;
    flex-direction: column;
  }
`
