import { useEffect } from 'react'
import { AD_CODE_ID } from '@app.feature/ad/constants/adCode'
import { SHOW_ALTERNATIVE_MODAL } from '@app.feature/ad/hooks/useFullScreenAdBanner'
import useGetAdsByPlacementCode from '@app.feature/ad/hooks/useGetAdsByPlacementCode'
import FullScreenAdBanner from './FullScreenAdBanner'

const FullScreenAdBannerContainer = () => {
  const { ads, loading, error } = useGetAdsByPlacementCode({
    code: AD_CODE_ID.FULLSCREEN_POPUP.PC,
    isShuffled: true,
  })

  useEffect(() => {
    if (!loading && !ads?.length) {
      sessionStorage.setItem(SHOW_ALTERNATIVE_MODAL, 'true')
    }
  }, [ads])

  if (loading || error || !ads?.length) return null

  return <FullScreenAdBanner ads={ads} />
}

export default FullScreenAdBannerContainer
