import { FC, useState } from 'react'
import { useCookies } from 'react-cookie'
import LazyLoad from 'react-lazyload'
import gql from 'graphql-tag'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { useGqlScreenHomeMobileQuery } from 'generated/graphql'
import BestStory from '@app.components/BestStory'
import { SHOW_ALTERNATIVE_MODAL } from '@app.feature/ad/hooks/useFullScreenAdBanner'
import FullScreenAdBannerContainer from '@app.feature/ad/mobile/FullScreenAdBanner/FullScreenAdBannerContainer'
import HotLineBannerStatusMobile from '@app.feature/ad/mobile/HotLineBanner/HotLineBannerStatus.mobile'
import LiveActivityStatusMobile from '@app.feature/ad/mobile/LiveActivity/LiveActivityStatus.mobile'
import LiveActivityLoadingMobile from '@app.feature/ad/mobile/LiveActivity/loading/LiveActivityLoading.mobile'
import MainBannerStatusMobile from '@app.feature/ad/mobile/MainBanner/MainBannerStatus.mobile'
import PopularActivityStatusMobile from '@app.feature/ad/mobile/PopularActivity/PopularActivityStatus.mobile'
import PopularActivityLoadingMobile from '@app.feature/ad/mobile/PopularActivity/loading/PopularActivityLoading.mobile'
import PopularRecruitStatusMobile from '@app.feature/ad/mobile/PopularRecruit/PopularRecruitStatus.mobile'
import PopularRecruitLoadingMobile from '@app.feature/ad/mobile/PopularRecruit/loading/PopularRecruitLoading.mobile'
import RecommendBannerListStatusMobile from '@app.feature/ad/mobile/RecommendBanner/list/RecommendBannerListStatus.mobile'
import PopularChatRoomsMobile from '@app.feature/home/components/PopularChat/mobile/PopularChatRooms.mobile'
import useSignupCookies, {
  SIGNUP_MODAL_COOKIE,
} from '@app.feature/home/hook/useSignupCookies'
import { BestStoryType } from 'app.constants/enums'
import { useBodyScrollHidden } from 'hooks/useBodyScrollHidden'
import UserInfoPreviewModal from '../../../userInfoModal/mobile/UserInfoPreviewModal'

gql`
  query gqlScreenHomeMobile {
    currentUser {
      isInitializedUserInfo
      type
      ...ActivityListCardItem_currentUser
    }
  }
`

const ScreenHomeMobile: FC = () => {
  const [cookie] = useCookies()
  const [isOpenSignupModal, setIsOpenSignupModal] = useState<boolean>(false)
  const { addSignupModalCookie } = useSignupCookies()
  const router = useRouter()

  const { data, loading } = useGqlScreenHomeMobileQuery({
    onCompleted: (data) => {
      if (data?.currentUser && !data?.currentUser?.isInitializedUserInfo) {
        const canOpenModal =
          !cookie?.[SIGNUP_MODAL_COOKIE] &&
          data?.currentUser?.type === 'NORMAL' &&
          data.currentUser?.isInitializedUserInfo === false &&
          sessionStorage.getItem(SHOW_ALTERNATIVE_MODAL) === 'true'
        setIsOpenSignupModal(() => canOpenModal)
      }
    },
  })

  useBodyScrollHidden(isOpenSignupModal)

  const handleCloseModal = () => {
    setIsOpenSignupModal(() => false)
    addSignupModalCookie()
  }
  const handleOpenModal = () => {
    router.push('/user-info-popup')
  }

  return (
    <StyledWrapper>
      <RecommendBannerListStatusMobile currentUser={data?.currentUser} />
      <MainBannerStatusMobile />
      <BestStory
        className="best-story"
        type={BestStoryType.ALL}
        isExpandBtn={false}
      />
      <HotLineBannerStatusMobile />
      <LazyLoad height={'100%'} placeholder={<PopularActivityLoadingMobile />}>
        <PopularActivityStatusMobile
          currentUser={data?.currentUser ?? undefined}
          isScreenQueryLoading={loading}
        />
      </LazyLoad>
      <LazyLoad height={'100%'} placeholder={<PopularRecruitLoadingMobile />}>
        <PopularRecruitStatusMobile
          isScreenQueryLoading={loading}
          currentUser={data?.currentUser || undefined}
        />
      </LazyLoad>
      <LazyLoad height={'100%'}>
        <PopularChatRoomsMobile />
      </LazyLoad>
      <LazyLoad height={'100%'} placeholder={<LiveActivityLoadingMobile />}>
        <LiveActivityStatusMobile
          currentUser={data?.currentUser ?? undefined}
          isScreenQueryLoading={loading}
        />
      </LazyLoad>
      {isOpenSignupModal && (
        <UserInfoPreviewModal
          onClickCancel={handleCloseModal}
          onClickConfirm={handleOpenModal}
        />
      )}
      <FullScreenAdBannerContainer />
    </StyledWrapper>
  )
}

export default ScreenHomeMobile

const StyledWrapper = styled.div`
  .best-story {
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
  }
`
